import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, Button, DialogTitle, DialogContent, DialogActions, TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from '../../../styles/viewer.module.css';
import CuresAPI from '../../../services/CuresAPI';
import auth from '../../../utils/auth';

const RegisterModal = ({userData, open, setOpen, saved}) => {

    // TODO: ADD button to edit cures data
    const [userType, setUserType] = useState(null)
    const [userIdType, setUserIdType] = useState(null)
    const [businessName, setBusinessName] = useState(null)
    const [formData, setFormData] = useState({
        userId: '',
        familyName: '',
        givenName: ''
    })
    const [missingData, setMissingData] = useState(false)
    const [serverError, setServerError] = useState(false)

    const pharmacistOptions = [
        {id: 1, title: 'Bartell Drugs'},
        {id: 2, title: 'Boone Drug'},
        {id: 3, title: 'CVS Pharmacy'},
        {id: 4, title: 'Discount Drug Mart'},
        {id: 5, title: 'Family Pharmacy'},
        {id: 6, title: 'Good Neighbor Pharmacy'},
        {id: 7, title: 'Hartig Drug'},
        {id: 8, title: 'Health Mart'},
        {id: 9, title: 'Kinney Drugs'},
        {id: 10, title: 'Leader Drug Stores'},
    ]

    const handleFormData = ({target}) => {
        setFormData(formData => ({
            ...formData,
            [target.name]: target.value
        }))
    }

    const handleSubmit = () => {
        if(
            !formData.userId
            || !formData.familyName
            || !formData.givenName
        ) return setMissingData(true)
        if(userType === 'Prescriber' && !userIdType) return setMissingData(true)
        if(userType === 'Pharmacist' && !businessName) return setMissingData(true)
        setMissingData(false)
        const userData = {
            userType,
            userIdType,
            businessName: businessName?.title,
            formData,
            username: auth.username
        }
        CuresAPI.saveCuresUser(userData)
            .then(response => {
                saved()
                setOpen(false)
            })
            .catch(error => setServerError(true))
    }

    const fillUserData = useCallback(() => {
        const { providerData, providerType } = userData
        setUserType(providerType)
        setFormData({
          userId: providerData.userId,
          familyName: providerData.familyName,
          givenName: providerData.givenName
        })
        if(providerType === 'Prescriber') setUserIdType(providerData.userIdType)
        if(providerType === 'Pharmacist') setBusinessName({title: providerData.businessName})
    }, [userData]);

    useEffect(() => {
        if(userData?.providerData) return fillUserData()
    }, [userData, fillUserData])

    useEffect(() => {
        saved(false)
    }, [saved])

    return (
        <Dialog
            open={open}
            handleClose={() => setOpen(false)}
        >
            <DialogTitle>Please complete your profile to get CURES data</DialogTitle>
            <DialogContent>
                <div className={styles.curesRegistration}>
                    <TextField
                        label='Select your user type'
                        select
                        value={userType}
                        onChange={e => setUserType(e.target.value)}
                        style={{marginTop: '-10px'}}
                    >
                        <MenuItem value='Prescriber'>Prescriber</MenuItem>
                        <MenuItem value='Pharmacist'>Pharmacist</MenuItem>
                    </TextField>
                </div>
            {
                userType ?
                    <div className={styles.curesRegistration}>
                        <TextField onChange={handleFormData} name='userId' label={`${userType} ID*`} placeholder={`${userType} ID*`} value={formData.userId}/>
                    </div>
                :
                    null
            }
            {
                userType === 'Prescriber' ?
                <div className={styles.curesRegistration}>
                        <TextField
                            label={`${userType} ID Type*`}
                            select
                            value={userIdType}
                            onChange={e => setUserIdType(e.target.value)}
                            style={{marginTop: '10px'}}
                        >
                            <MenuItem value='NPI'>NPI</MenuItem>
                            <MenuItem value='DEA'>DEA</MenuItem>
                            <MenuItem value='SLN'>SLN</MenuItem>
                        </TextField>
                    </div>
                : userType === 'Pharmacist' ?
                    <div className={styles.curesRegistration}>
                        <Autocomplete
                            freeSolo
                            options={pharmacistOptions}
                            getOptionLabel={option => option.title}
                            value={businessName}
                            onChange={(_,newValue) => setBusinessName(newValue)}
                            style={{marginTop: '-10px'}}
                            renderInput={(params) => <TextField {...params} label="Business Name" margin="normal" />}
                        />
                    </div>
                : null
            }
            {
                userType ?
                    <div className={styles.curesRegistration}>
                        <TextField onChange={handleFormData} name='givenName' label="Given Name" placeholder={`Given Name*`} value={formData.givenName}/>
                        <TextField onChange={handleFormData} name='familyName' label="Family Name" placeholder={`Family Name*`} value={formData.familyName}/>
                    </div>
                :
                    null
            }
            {missingData && <p style={{color: 'red', fontSize: '0.9rem', textAlign: 'center', margin: '15px 0 0', fontWeight: 'bold'}}>Please fill all inputs</p>}
            {serverError && <p style={{color: 'red', fontSize: '0.9rem', textAlign: 'center', margin: '15px 0 0', fontWeight: 'bold'}}>An error ocurred, please try again</p>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>CANCEL</Button>
                {userType && <Button onClick={handleSubmit}>SAVE</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default RegisterModal
