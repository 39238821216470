import _ from 'lodash'
import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import colors from '../../utils/colors'
import { calculateAge } from '../../utils/constants'
import styles from '../../styles/viewer.module.css'

const theme = createMuiTheme({
  overrides: {
    MuiContainer: {
      root: {
        background: colors.baseBlue,
        color: colors.gray01,
        fontSize: '12px',
        fontWeight: '500',
        height: '75px',
        padding: '5px',
        textAlign: 'right'
      },
      maxWidthLg: {
        borderBottom: `1px solid ${colors.baseGray}`,
        maxWidth: 'initial !important'
      }
    }
  }
})

export default class PatientData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dob: '',
      patientData: {},
      name: 'Patient',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.patientData) {
      return null;
    }
    if (nextProps.patientData !== prevState.patientData) {
      const givenName = _.get(nextProps.patientData[0], 'resource.name.given[0]', '') || ''
      const family = _.get(nextProps.patientData[0], 'resource.name.family', '') || ''
      const birthdate = _.get(nextProps.patientData[0], 'resource.birthDate', '') || ''

      return {
        dob: birthdate,
        name: givenName + ' ' + family,
      }
    }
  }

  render() {
    const { patientData } = this.props

    return(
      <ThemeProvider theme={theme}>
        {!_.isEmpty(patientData) &&
        <Container className={styles.patientDetails}>
          <div className={styles.patientName}>
            {patientData.name || `${patientData.firstName} ${patientData.lastName}`}
          </div>
          <div className={styles.patientId}>
            MRN: {patientData.id ? patientData.id : 'N/A'}
          </div>
          {patientData.birthDate !== 'N/A' && <div className={styles.patientDob}>Age: {calculateAge(patientData.birthDate)}</div>}
          <div className={styles.patientDob}>DOB: {patientData.birthDate}</div>
        </Container>
        }
      </ThemeProvider>
    )
  }
}
