import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import 'moment/min/locales'
import { Container, Grid } from '@material-ui/core'
import DocumentData from './DocumentData'
import FileViewer from './FileViewer'
import PatientData from '../PatientData'
import Layout from '../../Layout'
import { Context } from '../../../store/AppStore'
import {
  getDocument
} from '../../../services/MintAPI'
import '../../../styles/index.css'
import styles from '../../../styles/viewer.module.css'
import { getNormalizedPatientData } from '../../../utils'

const DocumentFile = () => {
  const history = useHistory()
  const [state, dispatch] = useContext(Context)
  const { patientId, docReferenceId, dataType } = useParams()
  const [patientInfo, setPatientInfo] = useState([])
  const [dataBase64, setDataBase64] = useState(null)
  const [emptyDocument, setEmptyDocument] = useState(null)
  const [error, setError] = useState('')
  const [fhirDocument, setFhirDocument] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let patientData = []
      let doc = {}
      if(state.patientData && state.patientData.id === patientId) {
        patientData = state.patientData
      } else {
        patientData = await getNormalizedPatientData(patientId)
      }
      setPatientInfo(patientData)

      doc = await getDocument({ docReferenceId })

      if(!doc.error) {
        setFhirDocument(doc);
        const base64 = _.get(doc, 'content[0].attachment.data', null)
        setDataBase64(base64)
        const isEmptyDocument = _.get(doc, 'isEmptyDocument', null)
        setEmptyDocument(isEmptyDocument)
      } else {
        setError(doc.error)
      }

      if(!state.selectedDocument) {
        let date = _.get(doc, 'content[0].attachment.creation', '')
        date = moment(new Date(date)).format("ll")
        date = date === 'Invalid date' ? '' : date
        let file = {}
        file['title'] = _.get(doc, 'content[0].attachment.title', 'Unnamed') || 'Unnamed'
        file['author'] = _.get(doc, 'author[0].display', 'N/A') || 'N/A'
        file['custodian'] = _.get(doc, 'custodian.display', 'N/A') || 'N/A'
        file['date'] = date
        file['contentType'] = _.get(doc, 'content[0].attachment.contentType', '')
        dispatch({ type: 'UPDATE_DOCUMENT_DATA', payload: file})
      }

    }
    fetchData()
  }, [patientId, dispatch, docReferenceId, state.patientData, state.selectedDocument])

  const closeDocument = () => {
    history.push('/documents/' + patientId)
  }

  return(
    <Layout openFile={true} patientData={patientInfo}>
      <Grid item xs={12}>
        <Container className="container-documents">
          <div className={styles.documentViewer}>
            {patientInfo && <PatientData patientData={patientInfo} />}
            <DocumentData document={state.selectedDocument} />
            {
              /*dataBase64 &&*/
              <FileViewer
                fileContentType={state.selectedDocument ? state.selectedDocument.contentType : null}
                fileData={dataBase64}
                fileType={dataType}
                handleBack={closeDocument}
                isEmptyDocument={emptyDocument}
                fhirDocument={fhirDocument}
              />
            }
            {
              !dataBase64 &&
              <Container className="container-error">
                {error}
              </Container>
            }
          </div>
        </Container>
      </Grid>
    </Layout>
  )
}

export default DocumentFile
