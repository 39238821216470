import { useCallback, useEffect, useRef, useState } from 'react';

function isInvalid (value) {
  return !value || value?.trim() === '';
}

export function useForm() {
  const fields = useRef({});
  const errors = useRef({});
  const [isInvalid, setIsInvalid] = useState(true);

  const addField = (name, field) => {
    fields.current[name] = field;
  }

  const notifyError = (fieldName, error) => {
    errors.current[fieldName] = error;
    let errorCount = 0;
    Object.values(errors.current).forEach(error => {
      if (error) {
        errorCount++;
      }
    });
    setIsInvalid(errorCount > 0);
  }

  const reset = () => {
    Object.values(fields.current).forEach(field => {
      field.reset();
    })
    setIsInvalid(false);
  }

  return {
    isInvalid,
    addField,
    notifyError,
    reset,
  }
}

export function useField(form, name, initialValue, addToForm = true) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const lastError = useRef('');

  const validate = useCallback(
    () => {
      lastError.current = isInvalid(value) ? `${name} is required.` : '';
      setError(lastError.current);
    },
    [value, name]
  );

  const reset = () => {
    setValue(initialValue);
    setError('');
    lastError.current = '';
  }

  const changeHandler = (event) => {
    setValue(event?.target?.value);
  }

  useEffect(
    () => {
      if (addToForm) {
        validate();
        form.notifyError(name, lastError.current);
      }
    },
    [addToForm, value, validate, form, name, error]
  );

  if (addToForm) {
    form.addField(name, { reset });
  }

  return {
    value,
    changeHandler,
    error,
    name,
  };
}

export function useFieldUnpacked(form, name, initialValue, addToForm = true) {
  const field = useField(form, name, initialValue, addToForm);
  return [
    field.value,
    field.changeHandler,
    field.error,
    field.name,
  ]
}
