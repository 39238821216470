import _ from 'lodash';
import axios from 'axios';
import auth from '../utils/auth';
import { fhirTypesUrl } from '../utils/constants';
import typecodes from './typecodes';

const TYPES_URL = `https://cors-anywhere.herokuapp.com/${fhirTypesUrl}`

const getDocumentTypes = async (fromUrl = false) => {
  let valueSet

  if(!fromUrl) {
    valueSet = typecodes
  } else {
    valueSet = await axios.get({
      url: TYPES_URL,
      method: 'GET',
      headers: { 'content-type': 'application/json', ...auth.header() },
      data: {}
      })
      .then(response => {
        return response.data
      })
  }

  return _.get(valueSet, 'compose.include[0].concept', []);
}

export default getDocumentTypes;
