import React, { Fragment, useEffect, useState } from 'react';
import { useParams, withRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import DocumentViewer from './PatientViewer/Documents/DocumentViewer';
import ReferralsViewer from './PatientViewer/Referrals/ReferralsViewer';
import CuresViewer from './PatientViewer/Cures/CuresViewer';
import PreventionLinkReferralsViewer
  from './PatientViewer/Referrals/PreventionLinkReferralsViewer';
import Layout from './Layout';
import '../styles/index.css';
import PatientData from './PatientViewer/PatientData';
import auth from '../utils/auth';
import { patientDataStore } from '../store/PatientDataStore';
import styles from '../styles/viewer.module.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { routes } from '../Router';
import { PatientTabs } from './PatientTabs';
import { showReferrals } from '../utils/constants';


const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        width: '75%'
      },
      root: {
        width: '75%'
      }
    }
  }
})

const PatientInfo = () => {
  const { patientId, app } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPatientData(await patientDataStore.getPatientData(patientId));
      }
      catch (error) {
        setError(error.message);
      }
    }
    fetchData()
  }, [patientId])

  const searchOption = !!app

  const tabs = [
    {
      label: 'Documents',
      routeName: 'PATIENT_DOCUMENTS',
      component: <DocumentViewer />,
    },
    {
      label: 'Referrals',
      routeName: 'PATIENT_REFERRALS',
      component: <ReferralsViewer />,
      show: showReferrals,
    },
    {
      label: 'CURES',
      routeName: 'CURES',
      component: <CuresViewer />,
      show: auth.permissions.isCuresEnabled(),
    },
    {
      label: 'PreventionLink',
      routeName: 'PATIENT_PREVENTION_LINK',
      component: <PreventionLinkReferralsViewer />,
      show: auth.permissions.isPreventionLinkEnabled(),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout openFile={false} patientData={patientData} searchNav={searchOption}>
        <Grid item xs={12}>
          <Container className="container-documents">
            {
              !patientData && !error &&
              <ThemeProvider theme={theme}>
                <Container className={styles.centeredProgress}>
                  <CircularProgress/>
                </Container>
              </ThemeProvider>
            }
            {
              !patientData && error &&
              <ThemeProvider theme={theme}>
                <Container className={styles.errorContainer}>
                  {error}
                </Container>
              </ThemeProvider>
            }
            {
              patientData && !error &&
              <Fragment>
                <PatientData patientData={patientData}/>
                <PatientTabs
                  patientId={patientId}
                  app={app}
                  routes={routes}
                  tabs={tabs}
                />
              </Fragment>
            }
          </Container>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default withRouter(PatientInfo)
