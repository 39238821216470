import React, { Component, createContext } from 'react';
import axios from 'axios';
import { checkUser } from './utils/constants';
import auth from './utils/auth';

export const AuthContext = createContext();

class AuthProvider extends Component {

  state = {
    requested: false,
    userInfo: null,
    token: null,
  };

  async componentDidMount() {
    await this.getUserDetails();
  }

  getUserDetails = async () => {
    auth.initUser();
    let data = auth.user();
    if (!data) {
      data = await this.requestUserDetails();
    }
    this.setState({
      userInfo: data?data.userinfo:null,
      token: data,
      requested:true
    });
  };

  requestUserDetails = async () => {
    let userDetails = null;
    try{
    const response = await axios.get(checkUser + auth.usernameParameter);
    if (response.status === 200 && response.data)
      userDetails = response.data;
    }catch(e){
      console.error('Error requesting user details',e);
    }
    return userDetails;
  };

  render() {
    const { token } = this.state;
    const { children } = this.props;
    return this.state.requested?(
      <AuthContext.Provider value={token}>{children}</AuthContext.Provider>
    ):(
      <div className="main-loading">Loading...</div>
    );
  }
}

export default AuthProvider;
