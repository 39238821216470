import React, { useEffect, useState } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
import colors from '../utils/colors'
import { AppVersion } from '../AppVersion';
import { getServerLastCommit, supportEmail } from '../utils/constants';
import { getSessionInfo } from '../services/MintAPI';

const DEFAULT_SUPPORT_URL = 'https://support.nicheaim.com/';
const DEFAULT_SUPPORT_EMAIL = 'support@nicheaim.com';

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.baseBackground,
        color: colors.baseColor
      },
      positionStatic: {
        bottom: '0',
        position: 'absolute',
        textAlign: 'center',
        top: 'auto'
      }
    },
    MuiTypography: {
      root: {
        fontSize: '16px !important',
        lineHeight: '24px !important',
        margin: '8px'
        //margin: '1.5em 0'
      }
    }
  }
})

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
})

let SERVER_COMMIT_RESULT = null;

const Footer = () => {

  const [serverCommit, setServerCommit] = useState(null);

  useEffect(
    () => {
      if (!SERVER_COMMIT_RESULT) {
        axiosInstance({
          method: 'GET',
          url: getServerLastCommit,
        })
          .then(response => {
            const { data } = response;
            const _commitInfo = { commitInfo: data };
            setServerCommit(_commitInfo);
            SERVER_COMMIT_RESULT = _commitInfo;
          })
          .catch(result => {
            const data = result?.response?.data || 'No version information available';
            const _commitInfo = { error: data };
            setServerCommit(_commitInfo);
            SERVER_COMMIT_RESULT = _commitInfo;
          });
      }
      else {
        setServerCommit(SERVER_COMMIT_RESULT);
      }
    },
    []
  );

  const openSupportLink = async (event) => {
    event.preventDefault();
    window.open(await getSupportLink(), 'support-link-target');
  }

  return (
    <ThemeProvider theme={theme}>
      <iframe title='no-title' id='support-link-target' name='support-link-target' width={0} height={0} />
      <footer>
        <Typography variant="body2">
          <a href="https://www.patientholistics.com/">© 2020 Patient Holistics</a> -&nbsp;
          <a href="https://www.patientholistics.com/legal/terms-and-conditions">Terms & Conditions</a> |&nbsp;
          <a href="https://www.patientholistics.com/legal/privacy">Privacy Policy</a> |&nbsp;
          <a
            href={DEFAULT_SUPPORT_URL}
            target='support-link-target'
            onClick={openSupportLink}
          >
            Support
          </a>
        </Typography>
        <AppVersion commitResult={getClientCommit()}/>
        {
          (serverCommit || SERVER_COMMIT_RESULT) &&
          <AppVersion
            commitResult={serverCommit || SERVER_COMMIT_RESULT}
            isServer={true}
          />
        }
      </footer>
    </ThemeProvider>
  )
}

function getClientCommit() {
  try {
    return {
      commitInfo: require('../utils/last-commit-info.json')
    };
  }
  catch (error) {
    return {
      error: 'No version information available'
    }
  }
}

async function getSupportLink() {
  return encodeURI(
    [
      `mailto:${supportEmail || DEFAULT_SUPPORT_EMAIL}`,
      '?subject=PatientHolistics Support Request',
      `&body=${await getSupportEmailBody()}`,
    ]
      .join('')
  )
}

async function getSupportEmailBody() {
  const sessionInfo = await getSessionInfo();
  return [
    `URL: ${window.location.href}`,
    `Timestamp: ${moment(sessionInfo.timestamp).format('YYYY-MM-DD (dddd, MMMM Do, YYYY) [at] h:mm:ss A [UTC]')}`,
    `User ID: ${sessionInfo.userID || 'Unknown'}`,
    `Session ID: ${sessionInfo.sessionID}`,
    `IP Address: ${sessionInfo.ipAddress}`,
    `Browser: ${sessionInfo.browser}`,
    '\r\n[Insert your comments here]'
  ]
    .join('\r\n');
}

export default Footer;
