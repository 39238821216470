import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import { commentRenderer, dateTimeRenderer, statusRenderer } from '../value-renderers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(0, 97, 129)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function newRow (field, title, renderer) {
  return {
    title,
    renderValue(obj) {
      return renderer(get(obj, field), obj)
    }
  }
}

const rows = [
  newRow('status', 'Status', statusRenderer),
  newRow('statusComment', 'Comment', commentRenderer),
  newRow('description', 'Description', commentRenderer),
  newRow('statusTs', 'Date/Time', dateTimeRenderer),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReferralDetailTable({ referral }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table} aria-label="Referral Details Table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Referral Detail</StyledTableCell>
            <StyledTableCell>&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell>
                {row.renderValue(referral)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
