import React, { useState } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import PatientTable from './PatientTable'
import PatientFilters from './PatientFilters'
import { getPatients, provisionPatient } from '../../services/MintAPI';
import getPatientsList from '../../services/PatientsMock'
import { mockData } from '../../utils/constants'
import Container from '@material-ui/core/Container';
import styles from '../../styles/viewer.module.css';
import NewPatientDialog from '../PatientViewer/NewPatientDialog';

const theme = createMuiTheme({
  overrides: {}
})

const PatientSearch = ({
  handlePatientOnClick
}) => {
  const [patients, setPatients] = useState([])
  const [initialListState, setInitialListState] = useState([])
  const [filter, setFilter] = useState({
    patientId: '',
    birthDate: '',
    firstName: '',
    lastName: ''
  })
  const [ error, setError ] = useState('');
  const [showNewPatientDialog, setShowNewPatientDialog] = useState(false);

  const handleOnFilter = (data) => {
    setFilter({
      ...filter,
      [data.target.id]: data.target.value
    })
  }

  const handleSearchPatient = async () => {
    setInitialListState(null)
    let patientData;
    if(mockData) {
      patientData = getPatientsList(filter)
    } else {
      try {
        patientData = await getPatients(filter)
        setPatients(patientData)
        setInitialListState(patientData)
      }
      catch (error) {
        setError(error.message);
      }
    }
  }

  const handleNewPatientSave = async (fhirPatient, mrn) => {
    try {
      await provisionPatient(fhirPatient, mrn)
      setShowNewPatientDialog(false);
      return '';
    }
    catch (error) {
      return error.message;
    }
  }

  const handleNewPatient = () => {
    setShowNewPatientDialog(true);
  }

  const handleNewPatientClose = () => {
    setShowNewPatientDialog(false);
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>
          {error}
        </Container>
      </ThemeProvider>
    )
  }
  return(
    <ThemeProvider theme={theme}>
      <NewPatientDialog
        open={showNewPatientDialog}
        handleSave={handleNewPatientSave}
        handleClose={handleNewPatientClose}
      />
      <Grid container>
        <PatientFilters
          filter={filter}
          handleChange={handleOnFilter}
          handleSearchPatient={handleSearchPatient}
          handleNewPatient={handleNewPatient}
        />
        <PatientTable
          data={patients} onPatientClick={handlePatientOnClick}
          initialState={initialListState}
        />
      </Grid>
    </ThemeProvider>
  )
}

export default PatientSearch
