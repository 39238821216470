import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { TabPanel } from './TabPanel';

export function PatientTabs({ patientId, app, routes, tabs }) {

  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(getCurrentTabIndex());

  function getCurrentTabIndex() {
    return getVisibleTabs().findIndex(tab => tab.routeName === getCurrentRoute(routes).name);
  }

  function handleTabChange(event, newTabIndex) {
    setTabIndex(newTabIndex);
  }

  function getNamedRoutes() {
    return (routes || []).filter(route => route.name);
  }

  function getCurrentRoute() {
    return getNamedRoutes()
      .find(route => route.pathnameRegex.test(window.location.pathname));
  }

  function gotoRouteFactory(tab) {
    const route = getNamedRoutes(routes).find(route => route.name === tab.routeName);
    return () => {
      history.push(
        route.path
          .replace(':patientId', patientId)
          .replace(':app', app)
      );
    };
  }

  function getVisibleTabs() {
    return tabs
      .filter(tab => tab.show === undefined ? true : !!tab.show);
  }

  function geTabs() {
    return getVisibleTabs().map((tab, tabIndex) => {
      if (!tab.gotoRoute) {
        tab.gotoRoute = gotoRouteFactory(tab);
      }
      return <Tab label={tab.label} {...tabProps(tabIndex)} onClick={tab.gotoRoute} />
    })
  }

  function getTabPanels(tabIndex) {
    const components = getVisibleTabs().map(tab => tab.component);
    return components.map((component, componentIndex) => {
      return (
        <TabPanel value={tabIndex} index={componentIndex}>
          {component}
        </TabPanel>
      )
    });
  }

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="Patient information categories"
      >
        {
          geTabs()
        }
      </Tabs>
      {
        getTabPanels(tabIndex)
      }
    </>
  );
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
