import { getDocumentCatalogs } from '../../../services/MintAPI';
import { CatalogUtil } from '../../../utils';

const DOCUMENT_CATALOGS_KEY = 'DOCUMENT_CATALOGS';

let DOCUMENT_CATALOGS = null;

export class DocumentCatalogsStore {

  static async init() {
    if (DOCUMENT_CATALOGS === null) {
      DOCUMENT_CATALOGS = await getDocumentCatalogs()
      localStorage.setItem(DOCUMENT_CATALOGS_KEY, JSON.stringify(DOCUMENT_CATALOGS))
    }
  }

  static getDocumentCatalogs() {
    if (DOCUMENT_CATALOGS === null) {
      DOCUMENT_CATALOGS = localStorage.getItem(DOCUMENT_CATALOGS_KEY)
      if (DOCUMENT_CATALOGS) {
        DOCUMENT_CATALOGS = JSON.parse(DOCUMENT_CATALOGS);
      }
      else {
        DOCUMENT_CATALOGS = CatalogUtil.EMPTY_CATALOGS;
      }
    }
    return DOCUMENT_CATALOGS;
  }
}
