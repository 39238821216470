import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  faFile,
  faFileAlt,
  faFileCode,
  faFilePdf,
  faFileImage,
} from '@fortawesome/free-solid-svg-icons'
import DocumentList from './DocumentList'
import TypeSelect from './TypeSelect'
import getDocumentTypes from '../../../services/FhirAPI'
import { getDocuments } from '../../../services/MintAPI';
import { Context } from '../../../store/AppStore'
import styles from '../../../styles/viewer.module.css'
import UploadDocumentDialog from './UploadDocumentDialog';
import { CatalogUtil } from '../../../utils';

const DocumentViewer = () => {
  const history = useHistory()
  const dispatch = useContext(Context)[1];
  const { patientId } = useParams()
  const [docData, setDocData] = useState([])
  const [docTypes, setDocTypes] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [originalData, setOriginalData] = useState(null)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const setDocumentData = useCallback(
    (data, docTypes) => {
      return _.map(data, (document) => {
        const doc = document.resource || document
        let date = [
          _.get(doc, 'content[0].attachment.creation'),
          _.get(doc, 'date'),
        ]
          .find(Boolean)
        ;
        date = moment(date).format("ll")
        date = date === 'Invalid date' ? '' : date
        let file = {}

        file['title'] = _.get(doc, 'content[0].attachment.title', 'Unnamed') || 'Unnamed'
        file['author'] = _.get(doc, 'author[0].display', 'N/A') || 'N/A'
        file['facilityName'] = _.get(doc, 'context.encounter[0].display') || 'N/A';
        file['date'] = date
        file['typeCode'] = _.get(doc, 'type.coding[0].code', '')
        file['type'] = _.get(
          docTypes?.find(docType => docType?.code === file?.typeCode),
          'display',
          ''
        );
        file['format'] = getIcon(_.get(doc, 'content[0].attachment.contentType', ''))
        file['contentType'] = _.get(doc, 'content[0].attachment.contentType', '')
        file['data'] = _.get(doc, 'content[0].attachment.data', null) || null
        file['documentUniqueId'] = _.get(doc, 'identifier[0].value', null) || null
        file['repositoryUniqueId'] = _.get(doc, 'identifier[1].value', null) || null
        file['hcid'] = _.get(doc, 'identifier[2].value', null) || null
        file['docReferenceId'] = _.get(doc, 'id', null) || null

        return file
      })
    },
    []
  )

  const loadDocuments = useCallback(
    async (docTypes) => {
      let documents = await getDocuments({patientId: patientId})
      if(!documents.error) {
        documents = setDocumentData(documents, docTypes)
      }
      setDocData(documents)
      setOriginalData(documents)
      dispatch({ type: 'UPDATE_DOCUMENT_LIST', payload: documents })
    },
    [dispatch, patientId, setDocumentData]
  );

  useEffect(() => {
    const fetchData = async () => {
      const types = CatalogUtil.mergeWithCatalogTypeCodes(await getDocumentTypes());
      await loadDocuments(types);
      setDocTypes(types);
    }
    fetchData()
  }, [patientId, loadDocuments])

  const getIcon = (contentType) => {
    if ( _.includes(contentType,'pdf')) {
      return faFilePdf
    }
    else if ( _.includes(contentType,'xml')) {
      return faFileCode
    }
    else if ( _.includes(contentType,'image')) {
      return faFileImage
    }
    else if ( _.includes(contentType,'patientholistics')) {
       return faFileAlt
    }
    else {
       return faFile
    }
  }

  const handleChangeType = (event) => {
    const types = event.target.value
    let documents = []

    if( types.length > 0 ) {
      documents = _.filter(originalData, doc => {
        const code = _.get(doc, 'typeCode', '')
        return _.findIndex(types, {'code': String(code)}) > -1
      })
    } else {
      documents = originalData
    }

    setDocData(documents)
    setSelectedTypes(types)
    dispatch({ type: 'UPDATE_DOCUMENT_LIST', payload: documents })
  }

  const handleDeleteType = (value) => {
    let documents = []
    const types = _.filter(selectedTypes, type => {
      return type['code'] !== value
    })

    if( types.length > 0 ) {
      documents = _.filter(originalData, doc => {
        const code = _.get(doc, 'type.coding[0].code', '')
        return _.findIndex(types, {'code': String(code)}) > -1
      })
    } else {
      documents = originalData
    }

    setDocData(documents)
    setSelectedTypes(types)
    dispatch({ type: 'UPDATE_DOCUMENT_LIST', payload: documents })
  }

  const handleDocument = async (data) => {
    if( data ) {
      dispatch({ type: 'UPDATE_DOCUMENT_DATA', payload: data});
      let dataType = data?.format?.iconName;
      dataType = (dataType === 'file' && data?.contentType.endsWith('json'))
        ? 'file-alt'
        : dataType
      ;
      history.push(`/patient/${patientId}/file/${data.docReferenceId}/${dataType}`)
    }
  }

  const handleAddDocument = () => {
    setUploadDialogOpen(true);
  }

  const handleCloseDocumentDialog = () => {
    setUploadDialogOpen(false);
  }

  const handleSaveDocument = async () => {
    await loadDocuments();
    setUploadDialogOpen(false);
  }

  return(
    <div className={styles.documentViewer}>
      {originalData && !docData.error &&
        <TypeSelect
          handleChange={handleChangeType}
          handleDelete={handleDeleteType}
          typeDocuments={selectedTypes}
          types={docTypes}
          onAddDocument={handleAddDocument}
        />
      }
      <DocumentList
        patientId={patientId}
        data={docData}
        originalData={originalData}
        showDocument={handleDocument}
      />
      <UploadDocumentDialog
        open={uploadDialogOpen}
        patientID={patientId}
        handleSave={handleSaveDocument}
        handleClose={handleCloseDocumentDialog}
      />
    </div>
  )
}

export default DocumentViewer
