import { get, isArray } from 'lodash';

const MINT_ERROR_REGEXP = /.*Mint error: \(Status: (.+), Log ID: (.+)\) - \[ "?(.+)"? ] while calling .+/;

export class ErrorUtils {

  static getAxiosError(error) {
    return get(error, 'response.data') || get(error, 'message');
  }

  static getMintError(error) {
    const axiosError = ErrorUtils.getAxiosError(error);
    if (axiosError) {
      const mintErrors = MINT_ERROR_REGEXP.exec(axiosError);
      if (!mintErrors || mintErrors.length < 2) {
        return axiosError;
      }
      const errorStatus = mintErrors[1];
      const mintLogId = mintErrors[2];
      let mintErrorMessage = mintErrors[3];
      if (mintErrorMessage.endsWith('"')) {
        mintErrorMessage = mintErrorMessage.substring(0, mintErrorMessage.length - 1);
      }
      return `Mint Error (Status: ${errorStatus}, Log ID: ${mintLogId}) - ${mintErrorMessage}`;
    }
    return axiosError;
  }

  static throwMintError(error) {
    throw new Error(ErrorUtils.getMintError(error));
  }

  static rejectMintError(error) {
    return Promise.reject(new Error(ErrorUtils.getMintError(error)));
  }

  static configureAxiosForMintErrors(axiosInstance) {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        return ErrorUtils.rejectMintError(error);
      }
    )
    return axiosInstance;
  }

  static getData(axiosResult) {
    return get(axiosResult, 'data');
  }

  static toEmptyArray(axiosResult) {
    const data = ErrorUtils.getData(axiosResult);
    return isArray(data) ? data : [];
  }
}
