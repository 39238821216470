import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dateTimeRenderer, yesNoRenderer } from '../value-renderers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(0, 97, 129)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReferralNotesTable({ notes }) {
  const classes = useStyles();
  return (
    <>
      <h1 style={{
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
      }}>
        Referral Notes
      </h1>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="Referral Details Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date/Time</StyledTableCell>
              <StyledTableCell>From Referrer?</StyledTableCell>
              <StyledTableCell>Note</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.map((note) => (
              <StyledTableRow key={note.createTs}>
                <StyledTableCell component="th" scope="row">
                  {dateTimeRenderer(note.createTs)}
                </StyledTableCell>
                <StyledTableCell>
                  {yesNoRenderer(note.noteFromReferrer)}
                </StyledTableCell>
                <StyledTableCell>
                  {note.note}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
