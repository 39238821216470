import { getPatientData } from '../services/MintAPI';
import { get } from 'lodash';
import moment from 'moment';
import { NotFoundException } from './exceptions';

export async function getNormalizedPatientData(patientId)
{
  const fhirPatients = await getPatientData(patientId);
  if (get(fhirPatients, 'length', 0) === 0) {
    throw new NotFoundException(`Patient with ID '${patientId}' was not found.`)
  }
  const resource = get(fhirPatients[0], 'resource', null) || null;
  const firstName = get(resource, 'name[0].given[0]', '') || '';
  const lastName = get(resource, 'name[0].family', '') || '';
  let bdate = get(resource, 'birthDate', '');
  bdate = moment(bdate, 'YYYY-MM-DD').format("ll");
  bdate = bdate === 'Invalid date' ? '' : bdate;
  return {
    name: `${firstName} ${lastName}`,
    id: get(resource, 'id', null) || null,
    birthDate: bdate || 'N/A',
    fhirPatient: resource,
  };
}
