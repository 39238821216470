import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { ReferralUtils } from '../../../services';
import { isReferringOrganization } from '../../../services/ReferralStates';
import auth from '../../../utils/auth';

const useStyles = makeStyles({
  root: {
    marginLeft: '17px',
    marginRight: 'auto',
    marginBottom: '5px',
    backgroundColor: '#E5E5E8',
    padding: '10px',
    paddingLeft: '15px',
    borderRadius: '4px',
    border: '1px solid #D2D2D2',
  },
  table: {
    fontSize: '12px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#066280',
  },
  header: {
    paddingRight: '10px',
    fontWeight: 'bold',
    color: '#01668B',
    textTransform: 'uppercase',
  },
  leftValue: {
    paddingRight: '100px',
  },
  urgent: {
    color: 'red',
    fontWeight: 'bold',
  },
  normal: {
    color: '#066280',
    fontWeight: 'normal',
  }
});

function ReferralCard(props) {
  const classes = useStyles();
  const { referral } = props;
  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <tr>
          <td className={classes.header}>
            Patient Name:
          </td>
          <td className={classes.leftValue}>
            {`${referral.givenName} ${referral.middleName || ''}${referral.familyName}`}
          </td>
          <td className={classes.header}>
            Referrer:
          </td>
          <td className={classes.leftValue}>
            {referral.referringOrganizationName}
          </td>
        </tr>
        <tr>
          <td className={classes.header}>
            MRN:
          </td>
          <td className={classes.leftValue}>
            {
              isReferringOrganization(auth, referral)
                ? referral.referringPatientId
                : referral.receivingPatientId
            }
          </td>
          <td className={classes.header}>
            Referee:
          </td>
          <td>
            {referral.receivingOrganizationName}
          </td>
        </tr>
        <tr>
          <td className={classes.header}>
            Date of Birth:
          </td>
          <td className={classes.leftValue}>
            {moment(referral.birthdate).format('ll')}
          </td>
          <td className={classes.header}>
            Status:
          </td>
          <td>
            {
              referral.rejected
                ? <span className={classes.urgent}>REJECTED</span>
                : referral.status.toUpperCase()
            }
          </td>
        </tr>
        <tr>
          <td className={classes.header}>
            Age:
          </td>
          <td className={classes.leftValue}>
            {new Date().getFullYear() - moment(referral.birthdate).toDate().getFullYear()}
          </td>
          <td className={classes.header}>
            Urgent?
          </td>
          <td>
            <span className={referral.urgent ? classes.urgent : classes.normal}>
              {referral.urgent ? 'YES' : 'NO'}
            </span>
          </td>
        </tr>
        <tr>
          <td className={classes.header}>
            Sex:
          </td>
          <td className={classes.leftValue}>
            {
              {'M': 'Male', 'F': 'Female', 'O': 'Other'}[referral.sex]
            }
          </td>
          <td className={classes.header}>
            Date / Time:
          </td>
          <td>
            {moment(referral.createTs).format('llll [UTC]')}
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td className={classes.header}>
            Description:
          </td>
          <td>
            {referral.description}
          </td>
        </tr>
      </table>
    </div>
  );
}

ReferralCard.propTypes = {
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE.isRequired,
}

export default ReferralCard;
