import { get } from 'lodash';

function getOrganizationName(auth) {
  return get(auth, 'loggedInUser.userinfo.orgName');
}

export function isReferringOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.referringOrganizationName;
}

export function isReferredOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.receivingOrganizationName
}

export function getPatientId(auth, referral) {
  return isReferringOrganization(auth, referral)
    ? referral.referringPatientId
    : referral.receivingPatientId || referral.referringPatientId
}

function isReferringUser(auth, referral) {
  return (
    isReferringOrganization(auth, referral)
    && auth.mintUserId === referral.referringMintUserId
  );
}

function isPatientProvisioned(referral) {
  return referral.receivingPatientId;
}

function isOpen (referral) {
  return (
    [
      ReferralStates.NEW,
      ReferralStates.RECEIVED,
      ReferralStates.ADDRESSED,
    ]
      .includes(referral.status)
  );
}

export default class ReferralStates {

  static NEW = 'new';
  static RECEIVED = 'received';
  static ADDRESSED = 'addressed';
  static COMPLETED = 'completed';

  static canWithdraw(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringUser(auth, referral)
      && isOpen(referral)
    );
  }

  static canAddNote(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
      && isPatientProvisioned(referral)
      && (
        isReferringUser(auth, referral)
        || isReferredOrganization(auth, referral)
      )
    );
  }

  static canAccept(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferredOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.NEW
    )
  }

  static canReject(auth, referral) {
    return (
      isReferredOrganization(auth, referral)
      && isOpen(referral)
    )
  }

  static canAddress(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferredOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.RECEIVED
    )
  }

  static canComplete(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferredOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.ADDRESSED
    )
  }

  static canProvision(auth, referral) {
    return (
      isReferredOrganization(auth, referral)
      && isOpen(referral)
      && !isPatientProvisioned(referral)
    )
  }

  static canAddDocument(auth, referral) {
    return isOpen(referral);
  }
}
