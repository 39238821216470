import React, { Fragment } from 'react';
import DocumentsTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../styles/viewer.module.css';
import { baseTheme } from '../theme';
import { createMuiTheme } from '@material-ui/core/styles';
import { tableIcons } from '../table-icons';
import colors from '../../../utils/colors';

const tableTheme = createMuiTheme({
  overrides: {
    ...baseTheme.overrides,
    MuiTableCell: {
      head: {
        background: colors.blueGray,
        fontWeight: '600',
        textAlign: 'left'
      },
      body: {
        color: `${colors.baseGray} !important`,
        fontSize: '11px !important',
        textAlign: 'left !important'
      }
    }
  }
})

const ReferralDocumentList = ({
  data,
  showDocument,
  columns = [
    {
      field: 'format',
      cellStyle: {
        width: 5,
      },
      render: rowData => {
        return (
          <Tooltip title={rowData.contentType}>
            <div>
              <FontAwesomeIcon
                icon={rowData.iconName} size="2x" className={styles.typeIcon}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Title',
      field: 'title',
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Author',
      field: 'author',
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Date / Time',
      field: 'creationDate',
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Document Type',
      field: 'type',
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Description',
      field: 'description',
      cellStyle: {
        width: '20%',
      },
    },
  ]
}) => {
  try {
    if (data) {
      return (
        <Fragment>
          <ThemeProvider theme={tableTheme}>
            <DocumentsTable
              columns={columns} data={data} icons={tableIcons}
              onRowClick={(e, data) => showDocument(data)}
              style={{
                border: '1px groove #A8A8A8',
              }}
              options={{
                minBodyHeight: '100%',
                maxBodyHeight: '100%',
                padding: 'dense',
                paging: true,
                search: false,
                showTitle: false,
                toolbar: false,
            }}
            />
          </ThemeProvider>
        </Fragment>
      );
    }
    else {
      return (
        <ThemeProvider theme={tableTheme}>
          <div>
            <CircularProgress/>
          </div>
        </ThemeProvider>
      );
    }
  }
  catch (error) {
    return (
      <ThemeProvider theme={tableTheme}>
        <Container className={styles.errorContainer}>
          {error.message}
        </Container>
      </ThemeProvider>
    );
  }
};

export default ReferralDocumentList;
