import { DEVELOPMENT_DB_USERNAME, login, logoutUrl } from './constants';
import { InvalidTokenException, TokenExpiredException } from "./exceptions";
import { get, isEmpty } from "lodash";
import jwt_decode from "jwt-decode";

import jwtDecode from'jwt-decode';

const ACL = (process.env.REACT_APP_USER_PERMISSIONS || '')
  .split(',')
  .map(permission => permission.trim().toUpperCase())
  .filter(permission => permission)
;

const PERMISSIONS = {
  canPostDocument() {
    return ACL.includes('DOCUMENT_POST');
  },
  isCuresEnabled() {
    return ACL.includes('CURES');
  },
  isPreventionLinkEnabled() {
    return ACL.includes('PREVENTION_LINK');
  },
}

class Auth {

  permissions = PERMISSIONS;

  constructor(storage, key) {
    this.storage = storage;
    this.key = key;
    this.loggedInUser = null;
    this.orgId = null;
    this.mintUserId = null;
    this.provisioned = false;
  }

  login(user, callback) {
    this.loggedInUser = user;
    if (callback) {
      callback();
    }
  }

  setLogoutRequested() {
    this.storage.setItem('LOGOUT_REQUESTED', 'true');
  }

  isLogoutRequested() {
    return this.storage.getItem('LOGOUT_REQUESTED') === 'true';
  }

  removeLogoutRequested() {
    this.storage.removeItem('LOGOUT_REQUESTED');
  }

  logout = (logoutType, callback) => {
    this.setLogoutRequested();
    this.clearUser(() => {
      if (callback) {
        callback();
      }
      this.goToLogoutRedirectUrl(logoutType);
    });
  }

  goToLogoutRedirectUrl(logoutType) {
    window.location = this.getLogoutRedirectUrl(logoutType);;
  }

  getLogoutRedirectUrl(logoutType) {
    const url = new URL(logoutUrl);
    if (this.loggedInUser) {
      if (this.loggedInUser.access_token) {
        url.searchParams.append('token', this.loggedInUser.access_token)
      }
      if (logoutType) {
        url.searchParams.append('type', logoutType)
      }
    }
    return url;
  }

  clearUser = (callback) => {
    this.loggedInUser = null;
    this.storage.removeItem(this.key);
    if (callback) {
      callback();
    }
  }

  getUserFromStorage() {
    const storedUser = this.storage.getItem(this.key);
    if (storedUser) {
      return JSON.parse(storedUser);
    }
    return storedUser;
  }

  setUserToStorage(user) {
    this.storage.setItem(this.key, JSON.stringify(user));
  }

  user = () => {
    if (!this.loggedInUser) {
      this.loggedInUser = this.getUserFromStorage();
    }
    return this.loggedInUser;
  }

  get username() {
    return this.user().userinfo.preferred_username;
  }

  get fullName() {
    return this.user().userinfo.name;
  }

  token = () => {
    const accessToken = get(this.user(), 'access_token', null);
    if (accessToken && process.env.REACT_APP_DEVELOPMENT_MODE !== 'true') {
      const decodedToken = jwt_decode(accessToken);
      const currentEpochTime = Math.floor(new Date().getTime() / 1000);
      if (decodedToken.exp <= currentEpochTime) {
        console.error('Token is expired.');
        this.logout()
      }
    }
    return accessToken;
  }

  header = () => {
    const token = this.token();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  logoutIfRequested() {
    if (this.isLogoutRequested()) {
      this.removeLogoutRequested();
      this.clearUser(() => this.goToLogoutRedirectUrl());
      return true;
    }
    return false;
  }

  initUser(userContext) {
    if (this.logoutIfRequested()) {
      return;
    }
    try{
      let result = null;
      if(userContext){
        this.setUserToStorage(userContext);
        this.login(userContext);
        result = userContext;
      }else{
        try {
          let user = this.getUserFromUrl();
          if (user) {
            this._isSaml = true;
            let token={
              access_token:user.token,
              expires_in:user.expires,
              userinfo:{
                preferred_username:user.username,
                orgName: user.orgName,
              }
            }
            result = token;
            this.login(result);
          }
        }
        catch (error) {
          console.error('error gettinguser from url',error);
        }
      }
      return result;
    } catch(error) {
      if(error && error.response && error.response.data) {
        const { message } = error.response.data;
        return { error: message }
      }
    }
  }

  isAuthenticated(userContext) {
    this.initUser(userContext);
    return !!this.loggedInUser;
  }


  getUserFromUrl(url = get(window, 'location.href')){
    const _url = new URL(url);
    let fragment = _url.hash;
    if (fragment.includes('?')) {
      fragment = fragment.substring(fragment.lastIndexOf('?') + 1);
    }
    else {
      fragment = fragment.replace('#', '?');
    }
    let token = this.getTokenFromUrlFragment(fragment);
    if (!token) {
      token = this.getTokenFromUrlFragment(_url.search);
    }
    if (token) {
      let user = this.parseToken(token);
      const orgName = _url.searchParams.get('orgName');
      if (orgName) {
        user.orgName = orgName;
      }
      return user;
    }
    return null;
  }

  getTokenFromUrlFragment(fragment) {
    if (fragment) {
      const urlSearchParams = new URLSearchParams(fragment);
      let token = urlSearchParams.get('id_token');
      if (!token) {
        token = urlSearchParams.get('token');
      }
      return token;
    }
    return null;
  }
  parseToken(token) {
    let tokenUser;
    try {
      tokenUser = jwtDecode(token);
    }
    catch (error) {
      if (isEmpty(token)) {
        throw new InvalidTokenException('Token is empty.');
      }
      else {
        throw new InvalidTokenException('Token is invalid.');
      }
    }
    if (!tokenUser.exp) {
      throw new InvalidTokenException('Token does not specify an expiration.');
    }
    if (!tokenUser.username && !tokenUser.email) {
      throw new InvalidTokenException('Token does not specify a username or email.');
    }
    if (new Date().getTime() >= new Date(tokenUser.exp * 1000).getTime()) {
      throw new TokenExpiredException();
    }
    return {
      token,
      expires: tokenUser.exp,
      username: tokenUser.username || tokenUser.email
    };
  }

  get canSeeAllReferrals() {
    return true;
  }

  get usernameParameter() {
    if (process.env.REACT_APP_DEVELOPMENT_MODE === 'true') {
      return `?username=${encodeURIComponent(DEVELOPMENT_DB_USERNAME)}`;
    }
    return '';
  }

  getLoginUrl() {
    return login + this.usernameParameter;
  }

}

export default new Auth(localStorage, 'medicaUser');
