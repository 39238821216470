import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export const baseTheme = {
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '50%',
        position: 'absolute',
        top: '150px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
    MuiFormControl: {
      root: {
        marginRight: '15px',
        position: 'absolute',
        right: '0',
        top: '16px',
        width: '20%'
      }
    },
    MuiInputBase: {
      root: {
        background: colors.gray01,
        borderRadius: '5px',
        fontSize: '12px'
      },
      input: {
        color: colors.baseBlue
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '0'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        height: '16px',
        width: '16px'
      },
      fontSizeSmall: {
        marginLeft: '5px'
      }
    },
    MuiTablePagination: {
      input: {
        flexShrink: 'initial'
      },
      toolbar: { background: colors.primaryColor }
    },
    MuiTableRow: {
      root: {
        '&:nth-child(odd)': {
          background: colors.gray01
        },
        '&:nth-child(even)': {
          background: colors.gray02
        }
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.baseBlue,
        fontSize: '12px',
        textTransform: 'uppercase'
      }
    },
    MuiToolbar: {
      root: {
        background: colors.lightGray
      }
    },
  },
};

const tableCellTheme = {
  MuiTableCell: {
    head: {
      background: colors.blueGray,
      fontWeight: '600',
      textAlign: 'center'
    },
    body: {
      color: `${colors.baseGray} !important`,
      fontSize: '11px !important',
      textAlign: 'center !important'
    }
  }
};

export const theme = createMuiTheme({
  overrides: {
    ...baseTheme.overrides,
    ...tableCellTheme,
  }
});
