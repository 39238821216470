import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ReferralsList from './ReferralsList';
import { mockReferrals } from '../../../utils/constants';
import styles from '../../../styles/viewer.module.css';
import MockReferralsAPI from '../../../services/MockReferralsAPI';
import ReferralsAPI from '../../../services/ReferralsAPI';
import { Context } from '../../../store/AppStore';
import { ReferralActions, ReferralActionButton } from './ReferralActions';
import PreventionLinkReferralDialog from './PreventionLinkReferralDialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../theme';
import { Container } from '@material-ui/core';
import auth from '../../../utils/auth';
import { patientDataStore } from '../../../store/PatientDataStore';

const FORM_UUID = process.env.REACT_APP_REFERRAL_FORM_UUID;

const API = mockReferrals ? MockReferralsAPI : ReferralsAPI;

const PreventionLinkReferralsViewer = (props) => {
  const history = useHistory()
  const [, dispatch] = useContext(Context)
  const { patientId } = useParams()
  const [ referrals, setReferrals ] = useState(null);
  const [ referralDialogOpen, setReferralDialogOpen ] = useState(false);
  const [ referralDialogSize, setReferralDialogSize ] = useState(null);
  const [ serviceCategories, setServiceCategories ] = useState(null);
  const [ organizationNames, setOrganizationNames ] = useState(null);
  const [ patientData, setPatientData ] = useState(null);
  const [ error, setError ] = useState('');
  const [ submitError, setSubmitError ] = useState(null);
  const [ showBackdrop, setShowBackdrop ] = useState(false);

  const showAllReferrals = props.showAllReferrals && auth.canSeeAllReferrals;

  const loadReferralData = useCallback(
    () => {
      ReferralsAPI.provisionUser(auth)
        .then(() => {
          if (!showAllReferrals) {
            loadReferralCatalogs();
          }
          API.getReferrals(props.showAllReferrals ? undefined : patientId)
            .then(referrals => {
              setReferrals(referrals);
            })
            .catch(error => {
              setError(error.message);
            });
        })
        .catch(error => {
          setError(error.message)
        });
    },
    [patientId, props.showAllReferrals, showAllReferrals]
  );

  const loadReferralCatalogs = () => {
    Promise.all([
      new Promise(async (resolve) => {
        resolve(await API.getServiceCategories(auth));
      }),
      new Promise(async (resolve) => {
        resolve(await API.getOrganizations(auth));
      }),
    ])
      .then(([categories, organizations]) => {
        setServiceCategories(categories)
        setOrganizationNames(organizations)
      })
    ;
  }

  const handleReferralSave = async (formEvent) => {
    try {
      setShowBackdrop(true);
      const { referralId } = await API.createPreventionLinkReferral(
        patientId, formEvent.payload
      );
      await loadReferralData();
      setShowBackdrop(false);
      setReferralDialogOpen(false);
      history.push(`/patient/search/${patientId}/info/1/referrals/${referralId}`);
      return '';
    }
    catch (error) {
      setSubmitError(error);
      setShowBackdrop(false);
      return error.message;
    }
  }

  const messageListener = useRef(
    async (event) => {
      const formEvent = event.data;
      if (formEvent.type === 'form-submitted') {
        await handleReferralSave(formEvent);
      }
    }
  );

  useEffect(
    () => {
      loadReferralData()
    },
    [loadReferralData]
  );

  useEffect(
    () => {
      const listener = messageListener.current;
      ReferralsAPI.getReferralFormSize(FORM_UUID)
        .then(size => {
          window.addEventListener('message', listener);
          setReferralDialogSize(size)
        })
      ;
      return () => {
        window.removeEventListener('message', listener);
      }
    },
    []
  );

  useEffect(
    () => {
      patientDataStore.getPatientData(patientId).then(patientData => setPatientData(patientData));
    },
    [patientId]
  );

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: 'UPDATE_REFERRAL', payload: referral });
      if (props.showAllReferrals) {
        history.push(`/referrals/${referral.referralId}`);
      }
      else {
        history.push(`/patient/search/${patientId}/info/1/referrals/${referral.referralId}`);
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  }

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>
          {error}
        </Container>
      </ThemeProvider>
    )
  }
  return(
    <Fragment>
      {
        serviceCategories && organizationNames && referralDialogSize && patientData &&
        <PreventionLinkReferralDialog
          open={referralDialogOpen}
          handleClose={handleReferralDialogClosed}
          formUUID={FORM_UUID}
          size={referralDialogSize}
          patientData={patientData}
          showBackdrop={showBackdrop}
          submitError={submitError}
        />
      }
      <div className={styles.documentViewer}>
        {
          referrals && serviceCategories && organizationNames && !props.showAllReferrals
          && referralDialogSize &&
          <ReferralActions>
            <ReferralActionButton text="Add a Referral" action={handleNewReferralClick} />
          </ReferralActions>
        }
        <ReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  )
}

export default withRouter(PreventionLinkReferralsViewer);
