import { curesUrl } from '../utils/constants';
import { ErrorUtils } from '../utils/exceptions';
import { AxiosHelper } from '../utils/AxiosHelper';

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosHelper.newInstance());

  export default class CuresAPI {
    static async getInfoByPrescriber(
        patientId,
        prescriberId,
        prescriberIdType,
        prescriberFamilyName,
        prescriberGivenName,
        activityStartDate,
        activityEndDate,
        ignoreAddress = 'Y'
    ) {
        const result = await axiosInstance.get(
          `${curesUrl}/byPrescriber`,
          {
            params: {
                patientId,
                prescriberId,
                prescriberIdType,
                prescriberFamilyName,
                prescriberGivenName,
                activityStartDate,
                activityEndDate,
                ignoreAddress
            }
          },
        );
        return result?.data;
    }

    static async getInfoByPharmacist(
        patientId,
        pharmacistId,
        pharmacyName,
        pharmacistFamilyName,
        pharmacistGivenName,
        activityStartDate,
        activityEndDate,
        ignoreAddress = 'Y'
    ) {
        const result = await axiosInstance.get(
          `${curesUrl}/byPharmacist`,
          {
            params: {
                patientId,
                pharmacistId,
                pharmacyName,
                pharmacistFamilyName,
                pharmacistGivenName,
                activityStartDate,
                activityEndDate,
                ignoreAddress
            }
          },
        );
        return result?.data;
    }

    static async saveCuresUser(userData){
      const result = await axiosInstance.post(
        `${curesUrl}/saveCuresUser`,
        userData,
      )
      return result?.data
    }

    static async getCuresUser(userName){
      const result = await axiosInstance.get(`${curesUrl}/getCuresUser`, { params: { userName } });
      return result?.data
    }
  }
