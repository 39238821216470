import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../../utils/colors';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '48%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  }
}));

function ReferralDialog(props) {

  const classes = useStyles();
  const [urgent, setUrgent] = useState(false);
  const [serviceCategory, setServiceCategory] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [description, setDescription] = useState('');
  const [serviceCategoryError, setServiceCategoryError] = useState(false);
  const [organizationNameError, setOrganizationNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const saveRequested = useRef(false);

  const isValid = (value, ignoreSaveRequested) => {
    return (saveRequested.current && !ignoreSaveRequested) ? (value.trim() !== '') : true;
  }

  const isInvalid = (value) => !isValid(value);

  const handleSave = async () => {
    saveRequested.current = true;
    setSaveError('');
    setServiceCategoryError(isInvalid(serviceCategory));
    setOrganizationNameError(isInvalid(organizationName));
    setDescriptionError(isInvalid(description));
    if (isInvalid(serviceCategory) || isInvalid(organizationName) || isInvalid(description)) {
      return;
    }
    setSaving(true);
    const referral = {
      description,
      patientId: '',
      urgent,
      serviceCategory,
      receiving: {
        organizationName
      }
    }
    let error = await props.handleSave(referral);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  const handleIsUrgentChange = (event) => {
    setUrgent(event.target.checked)
  }

  const handleServiceCategoryChange = (event) => {
    setServiceCategory(event.target.value);
    setServiceCategoryError(isInvalid(event.target.value, true));
  }

  const handleOrganizationNameChange = (event) => {
    setOrganizationName(event.target.value);
    setOrganizationNameError(isInvalid(event.target.value, true));
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(isInvalid(event.target.value, true));
  }

  const reset = () => {
    saveRequested.current = false;
    setUrgent(false);
    setServiceCategory('');
    setOrganizationName('');
    setDescription('');
    setServiceCategoryError(false);
    setOrganizationNameError(false);
    setDescriptionError(false);
    setSaving(false);
    setSaveError('');
  }

  return (
    <div>
      <Dialog
        open={props.open} onClose={props.handleClose} onEnter={reset} aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Referral</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControl className={classes.checkBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="referral-is-urgent"
                    autoFocus
                    checked={urgent}
                    onChange={handleIsUrgentChange}
                  />
                }
                label="Urgent"
              />
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={serviceCategoryError}>
              <InputLabel id="referral-service-category-label">
                Service Category
              </InputLabel>
              <Select
                labelId="referral-service-category-label"
                id="referral-service-category"
                required
                value={serviceCategory}
                onChange={handleServiceCategoryChange}
              >
                <MenuItem value=''>
                  None
                </MenuItem>
                {
                  props.serviceCategories.map(serviceCategory => (
                    <MenuItem value={serviceCategory}>
                      {serviceCategory}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                serviceCategoryError &&
                <FormHelperText>Service Category is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={organizationNameError}>
              <InputLabel id="referral-organization-name-label">
                Organization Name
              </InputLabel>
              <Select
                labelId="referral-organization-name-label"
                id="referral-organization-name"
                required
                value={organizationName}
                onChange={handleOrganizationNameChange}
              >
                <MenuItem value=''>
                  None
                </MenuItem>
                {
                  props.organizationNames.map(organizationName => (
                    <MenuItem value={organizationName}>
                      {organizationName}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                organizationNameError &&
                <FormHelperText>Organization Name is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={descriptionError}>
              <TextField
                id="referral-description"
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label="Description"
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
              />
              {
                descriptionError &&
                <FormHelperText>Description is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving || saveError}>
            Save
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

ReferralDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  serviceCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ReferralDialog;
