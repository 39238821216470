import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../../utils/colors';
import { DocumentUploader } from './DocumentUploader';
import { getMimeTypeFromFilename } from './getMimeTypeFromFilename';
import { set } from 'lodash';
import auth from '../../../utils/auth';
import { DocumentCatalogsStore } from './DocumentCatalogsStore';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '48%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  }
}));

const EMPTY_OPTION = { identifier: '', value: 'None' };

function UploadDocumentDialog(props) {

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const saveRequested = useRef(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [mimeType, setMimeType] = useState(EMPTY_OPTION);
  const [mimeTypeError, setMimeTypeError] = useState(false);
  const [classCode, setClassCode] = useState(EMPTY_OPTION);
  const [classCodeError, setClassCodeError] = useState(false);
  const [healthcareFacilityTypeCode, setHealthcareFacilityTypeCode] = useState(EMPTY_OPTION);
  const [healthcareFacilityTypeCodeError, setHealthcareFacilityTypeCodeError] = useState(false);
  const [practiceSettingCode, setPracticeSettingCode] = useState('');
  const [practiceSettingCodeError, setPracticeSettingCodeError] = useState(EMPTY_OPTION);
  const [typeCode, setTypeCode] = useState(EMPTY_OPTION);
  const [typeCodeError, setTypeCodeError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [uploader, setUploader] = useState(undefined);
  const [uploaderError, setUploaderError] = useState(false);
  const [uploaderErrorMessage, setUploaderErrorMessage] = useState('');
  const [resetUploader, setResetUploader] = useState(false);
  const [showMimeTypes, setShowMimeTypes] = useState(false);
  const catalogs = DocumentCatalogsStore.getDocumentCatalogs();

  const isNonEmptyString = (value) => {
    return saveRequested.current ? (value && value.trim && value.trim() !== '') : true;
  }

  const isEmptyString = (value) => !isNonEmptyString(value);

  const validateFilename = (uploader) => {
    let _mimeType = '';
    let uploaderError = false;
    let uploaderErrorMessage = '';
    let hasExtension = false;
    if (!uploader) {
      uploaderError = true;
    }
    else {
      const filename = uploader.selectedFile.name;
      _mimeType = getMimeType(filename) || '';
      const extensionSearchResults = /^(.+)(\.[a-zA-Z0-9]+$)/.exec(filename);
      hasExtension = !!extensionSearchResults;
      if (hasExtension) {
        const bareFilename = extensionSearchResults[1];
        const extension = extensionSearchResults[2];
        if (_mimeType) {
          setTitle(bareFilename);
        }
        else {
          uploaderError = true;
          uploaderErrorMessage = `"${extension}" file extension is not supported.`;
        }
      }
      else {
        setTitle(filename);
      }
    }
    setUploader(uploader);
    setUploaderError(uploaderError);
    setUploaderErrorMessage(uploaderErrorMessage);
    setShowMimeTypes(!hasExtension && !uploaderError);
    if (_mimeType) {
      setMimeType(catalogs?catalogs.mimeTypes.find(item => item.identifier === _mimeType):null);
    }
    return {
      _mimeType,
      hasExtension,
    };
  }

  const handleError = (errorMessage) => {
    if (errorMessage) {
      setSaving(false);
      setSaveError(errorMessage);
      return true;
    }
    return false;
  }

  const getFhirDocument = () => {
    const doc = {};
    set(doc, 'resourceType', 'DocumentReference');
    set(doc, 'status', 'current');
    set(doc, 'description', description);
    set(doc, 'type.text', typeCode.identifier);
    set(doc, 'category[0].text', classCode.identifier);
    set(doc, 'author[0].display', auth.fullName);
    set(doc, 'context.facilityType.text', healthcareFacilityTypeCode.identifier);
    set(doc, 'context.practiceSetting.text', practiceSettingCode.identifier);
    set(doc, 'content[0].attachment.title', title);
    set(doc, 'content[0].attachment.contentType', mimeType.identifier);
    return doc;
  }

  const handleSave = async () => {
    saveRequested.current = true;
    setSaveError('');
    setTitleError(isEmptyString(title));

    const { _mimeType, hasExtension } = validateFilename(uploader);
    if (!_mimeType && !hasExtension) {
      setMimeTypeError(isEmptyString(mimeType.identifier));
    }
    setClassCodeError(isEmptyString(classCode.identifier));
    setHealthcareFacilityTypeCodeError(isEmptyString(healthcareFacilityTypeCode.identifier));
    setPracticeSettingCodeError(isEmptyString(practiceSettingCode.identifier));
    setTypeCodeError(isEmptyString(typeCode.identifier));
    setDescriptionError(isEmptyString(description));
    if (
      isEmptyString(title)
      || isEmptyString(mimeType.identifier)
      || isEmptyString(classCode.identifier)
      || isEmptyString(healthcareFacilityTypeCode.identifier)
      || isEmptyString(practiceSettingCode.identifier)
      || isEmptyString(typeCode.identifier)
      || isEmptyString(description)
    ) {
      return;
    }
    setSaving(true);
    const document = getFhirDocument();
    if (!handleError(await uploader.upload(uploader.selectedFile, document))) {
      handleError(await props.handleSave(document));
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setTitleError(isEmptyString(event.target.value));
  }

  const getMimeType = (filename) => {
    return getMimeTypeFromFilename(catalogs?catalogs.mimeTypeExtensions:null, filename);
  }

  const onFileSelected = (uploader) => {
    validateFilename(uploader);
    setSaveError('')
  }

  const handleMimeTypeChange = (event, newValue) => {
    setMimeType(newValue);
    setMimeTypeError(isEmptyString(newValue.identifier));
  }

  const handleClassCodeChange = (event, newValue) => {
    setClassCode(newValue);
    setClassCodeError(isEmptyString(newValue.identifier));
  }

  const handleHealthcareFacilityTypeCodeChange = (event, newValue) => {
    setHealthcareFacilityTypeCode(newValue);
    setHealthcareFacilityTypeCodeError(isEmptyString(newValue.identifier));
  }

  const handlePracticeSettingCodeChange = (event, newValue) => {
    setPracticeSettingCode(newValue);
    setPracticeSettingCodeError(isEmptyString(newValue.identifier));
  }

  const handleTypeCodeChange = (event, newValue) => {
    setTypeCode(newValue);
    setTypeCodeError(isEmptyString(newValue.identifier));
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(isEmptyString(event.target.value));
  }

  const reset = () => {
    saveRequested.current = false;
    setMimeType(EMPTY_OPTION);
    setMimeTypeError(false);
    setClassCode(EMPTY_OPTION);
    setClassCodeError(false);
    setHealthcareFacilityTypeCode(EMPTY_OPTION);
    setHealthcareFacilityTypeCodeError(false);
    setPracticeSettingCode(EMPTY_OPTION);
    setPracticeSettingCodeError(false);
    setTypeCode(EMPTY_OPTION);
    setTypeCodeError(false);
    setDescription('');
    setDescriptionError(false);
    setResetUploader(true);
    setUploader(null);
    setUploaderError('');
    setSaving(false);
    setSaveError('');
    setShowMimeTypes(false);
    setUploaderErrorMessage('');
    setTitle('');
    setTitleError(false);
  }

  const onUploaderReset = () => {
    setResetUploader(false);
  }

  const handleClose = () => {
    reset();
    props.handleClose();
  }

  const getOptions = (catalogListName) => {
    if(catalogs==null)
      return null;
    return [EMPTY_OPTION]
      .concat(
        catalogs[catalogListName].map(item => {
          const { identifier: id, value } = item;
          return {
            identifier: id,
            value: value ? `${id} - ${value}` : id
          };
        })
      );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onEnter={reset}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload Document</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControl className={classes.formControl} error={uploaderError}>
            <DocumentUploader
              patientID={props.patientID}
              onFileSelected={onFileSelected}
              reset={resetUploader}
              onReset={onUploaderReset}
              mimeTypeExtensions={catalogs?catalogs.mimeTypeExtensions:null}
            />
            {
              uploaderError &&
              <FormHelperText>{uploaderErrorMessage || 'File is required.'}</FormHelperText>
            }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={titleError}>
              <TextField
                id="document-title"
                required
                label="Document Title"
                fullWidth
                value={title}
                onChange={handleTitleChange}
              />
              {
                titleError &&
                <FormHelperText>Document Title is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          {
            showMimeTypes &&
            <FormGroup row>
              <FormControl className={classes.formControl} error={mimeTypeError}>
                <Autocomplete
                  autoComplete
                  disableClearable
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="document-mime-type"
                  required
                  options={getOptions('mimeTypes')}
                  getOptionLabel={option => option.value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Mime Type"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                  value={mimeType}
                  onChange={handleMimeTypeChange}
                >
                </Autocomplete>
                {
                  mimeTypeError &&
                  <FormHelperText>Mime Type is required.</FormHelperText>
                }
              </FormControl>
            </FormGroup>
          }
          <FormGroup row>
            <FormControl className={classes.formControl} error={classCodeError}>
              <Autocomplete
                autoComplete
                disableClearable
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="document-class-code"
                required
                options={getOptions('classCodes')}
                getOptionLabel={option => option.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Document Class"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
                value={classCode}
                onChange={handleClassCodeChange}
              >
              </Autocomplete>
              {
                classCodeError &&
                <FormHelperText>Document Class is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={typeCodeError}>
              <Autocomplete
                autoComplete
                disableClearable
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="document-type-code"
                required
                options={getOptions('typeCodes')}
                getOptionLabel={option => option.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Document Type"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                  )}
                value={typeCode}
                onChange={handleTypeCodeChange}
              >
              </Autocomplete>
              {
                typeCodeError &&
                <FormHelperText>Document Type is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={healthcareFacilityTypeCodeError}>
              <Autocomplete
                autoComplete
                disableClearable
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="healthcare-facility-type-code"
                required
                options={getOptions('healthcareFacilityTypeCodes')}
                getOptionLabel={option => option.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Facility Type"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
                value={healthcareFacilityTypeCode}
                onChange={handleHealthcareFacilityTypeCodeChange}
              >
              </Autocomplete>
              {
                healthcareFacilityTypeCodeError &&
                <FormHelperText>Facility Type is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={practiceSettingCodeError}>
              <Autocomplete
                autoComplete
                disableClearable
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="practice-setting-code"
                required
                options={getOptions('practiceSettingCodes')}
                getOptionLabel={option => option.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Practice Setting"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
                value={practiceSettingCode}
                onChange={handlePracticeSettingCodeChange}
              >
              </Autocomplete>
              {
                practiceSettingCodeError &&
                <FormHelperText>Practice Setting is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={descriptionError}>
              <TextField
                id="document-description"
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label="Description"
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
              />
              {
                descriptionError &&
                <FormHelperText>Description is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            Save
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

UploadDocumentDialog.propTypes = {
  patientID: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default UploadDocumentDialog;
